import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    withStyles,
} from '@material-ui/core';

import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import * as UrlUtils from '../../helpers/URL';
import * as NavigationUtils from '../../helpers/Navigation';
import { Auth as AuthLayout } from '../layouts';
import { AppContext } from '../../AppContext';

function SignIn(props) {
    const { authenticate } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [identified, setIdentified] = useState(false);
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState({});

    /**
     * Event listener that is called on usernameChip clicked.
     *
     * @return {undefined}
     */
    const handleUsernameChipClicked = () => {
        setUsername('');
        setIdentified(false);

        const { history, location } = props;

        history.push(`${location.pathname}`);
    };

    /**
     * Event listener that is called on password visibility toggle.
     *
     * @return {undefined}
     */
    const handlePasswordVisibilityToggle = () => {
        setPasswordVisibility(!passwordVisible);
    };

    /**
     * This should send an API request to identify the user.
     *
     * @param {string} username
     * @param {object} form
     *
     * @return {undefined}
     */
    const identify = async (username = null, { setErrors }) => {
        setLoading(true);

        try {
            const response = await axios.post('/api/v1/auth/identify', {
                username,
            });

            const { history, location } = props;

            setIdentified(true);
            setUsername(response.data);
            setLoading(false);

            const queryString = UrlUtils.queryString({
                username: response.data,
            });

            if (queryString === location.search) {
                return;
            }

            history.push(`${location.pathname}${queryString}`);
        } catch (error) {
            if (!error.response) {
                throw new Error('Unknown error');
            }

            const { errors } = error.response.data;

            if (errors) {
                setErrors(errors);
            }

            setLoading(false);
        }
    };

    /**
     * This should send an API request to request for authentication.
     * Reload if authenticated.
     *
     * @param {object} values
     * @param {object} form
     *
     * @return {undefined}
     */
    const signIn = async (values, form = {}) => {
        setLoading(true);

        try {
            const { password } = values;

            const response = await axios.post('/api/v1/auth/signin', {
                username,
                password,
            });

            authenticate(JSON.stringify(response.data));

            setLoading(false);
        } catch (error) {
            if (!error.response) {
                throw new Error('Unknown error');
            }

            const { errors } = error.response.data;

            if (errors) {
                form.setErrors(errors);
            }

            setLoading(false);
        }
    };

    /**
     * Event listener that is triggered when the sign in form is submitted.
     *
     * @param {object} event
     * @param {object} form
     *
     * @return {undefined}
     */
    const handleSignInSubmit = async (values, form) => {
        form.setSubmitting(false);

        try {
            if (!identified) {
                await identify(values.username, form);

                return;
            }

            await signIn(values, form);
        } catch (error) {
            setLoading(false);

            setMessage({
                type: 'error',
                title: 'Ha ocurrido un error!',
                body: 'Mmm? Problemas con tu internet? Probá de nuevo en un ratito!',
                action: () => window.location.reload(),
            });
        }
    };

    /**
     * Identify here after component mounts.
     */
    useEffect(() => {
        if (identified) {
            return;
        }

        const { location } = props;

        const q = UrlUtils.queryParams(location.search);

        if (q.hasOwnProperty('username') && q.username !== '') {
            identify(q.username, {});
        }
    }, [identified]);

    const { classes, ...other } = props;

    return (
        <AuthLayout
            {...other}
            title={
                identified
                    ? 'Bienvenido'
                    : 'Ingresar'
            }
            
            loading={loading}
            message={message}
        >
            <Formik
                initialValues={{
                    username,
                    password: '',
                }}
                onSubmit={handleSignInSubmit}
                validationSchema={Yup.object().shape({
                    [!identified
                        ? 'username'
                        : 'password']: Yup.string().required(
                        `El campo ${
                            !identified ? 'username' : 'password'
                        } es obligatorio`,
                    ),
                })}
            >
                {({ values, handleChange, errors, isSubmitting }) => (
                    <Form autoComplete="off">
                        <Grid container direction="column">
                            {!identified ? (
                                <>
                                    <Grid item className={classes.formGroup}>
                                        <TextField
                                            type="email"
                                            id="username"
                                            name="username"
                                            label="Usuario"
                                            placeholder="usuario@distribuidoramiyi.com.ar"
                                            value={values.username}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={errors.hasOwnProperty(
                                                'username',
                                            )}
                                            helperText={
                                                errors.hasOwnProperty(
                                                    'username',
                                                ) && errors.username
                                            }
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item className={classes.formGroup}>
                                        <TextField
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            id="password"
                                            name="password"
                                            label="Contraseña"
                                            placeholder="secret"
                                            value={values.password}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={errors.hasOwnProperty(
                                                'password',
                                            )}
                                            helperText={
                                                errors.hasOwnProperty(
                                                    'password',
                                                ) && errors.password
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={
                                                                handlePasswordVisibilityToggle
                                                            }
                                                        >
                                                            {passwordVisible ? (
                                                                <VisibilityOffIcon />
                                                            ) : (
                                                                <VisibilityIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Grid container justify="space-between">
                            <Grid item />

                            <Grid item className={classes.formGroup}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        (errors &&
                                            Object.keys(errors).length > 0) ||
                                        isSubmitting
                                    }
                                >
                                    Siguiente
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
}

const styles = theme => ({
    formGroup: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
});

export default withStyles(styles)(SignIn);
