/**
 * Register the service worker file.
 */
export function unregister() {
    if ('serviceWorker' in navigator) {
        console.log("Unregister SW")
        navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
          for(let registration of registrations) {
            registration.unregister();
          }
        });
    }
}

export function register(serviceWorkerFile) {
    if ('serviceWorker' in navigator) {
        if (serviceWorkerFile) {
            navigator.serviceWorker
                .register(`${serviceWorkerFile}`)
                .then()
                .catch();
        }
    }
}
