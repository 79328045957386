import React from 'react';
import ReactDOM from 'react-dom';

import { register as registerServiceWorker, unregister as unregisterServiceWorker } from './helpers/ServiceWorker';
import App from './App';
import { dark as darkTheme, light as lightTheme } from './themes/backoffice';


if (document.querySelector('#root')) {
    ReactDOM.render(
        <App
            environment="backoffice"
            darkTheme={darkTheme}
            lightTheme={lightTheme}
        />,
        document.querySelector('#root'),
    );
}

const swFilepath = document.querySelector('meta[name=sw-filepath]');

if (swFilepath && process.env.NODE_ENV === 'production') {
    unregisterServiceWorker();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
