import { Home } from '../views/__backoffice';
import * as Settings from '../views/__backoffice/settings';
import * as Users from '../views/__backoffice/users';
import * as Stock from '../views/__backoffice/stock';
import * as Orders from '../views/__backoffice/orders';
import * as Zone from '../views/__backoffice/zones';
import * as Neighborhood from '../views/__backoffice/neighborhoods';
import * as Category from '../views/__backoffice/categories';
import * as Products from '../views/__backoffice/products';
import * as Customers from '../views/__backoffice/customers';
import * as Provider from '../views/__backoffice/providers';

import * as Raise from '../views/__backoffice/raises';
import * as Stadistics from '../views/__backoffice/stadistics';

const resources = [
    {
        name: 'users.index',
        path: '/usuarios',
        component: Users.List,
    },

    {
        name: 'users.create',
        path: '/usuarios/crear',
        component: Users.Create,
    },

    {
        name: 'users.change-password',
        path: '/usuarios/:id/cambiar-password',
        component: Users.ChangePassword,
    },
    {
        name: 'users.edit',
        path: '/usuarios/:id/editar',
        component: Users.Edit,
    },

].map(route => {
    route.name = `resources.${route.name}`;
    route.path = `/resources${route.path}`;

    return route;
});

const admin = [
    {
        name: 'categories.index',
        path: '/categorias',
        
        component: Category.List,
    },
    {
        name: 'categories.create',
        path: '/categorias/crear',
        component: Category.Create,
    },
    {
        name: 'categories.edit',
        path: '/categorias/:id/editar',
        component: Category.Edit,
    },
    {
        name: 'neighborhoods.index',
        path: '/barrios',
        
        component: Neighborhood.List,
    },
    {
        name: 'neighborhoods.create',
        path: '/barrios/crear',
        
        component: Neighborhood.Create,
    },
    {
        name: 'neighborhoods.edit',
        path: '/barrios/:id/editar',
        
        component: Neighborhood.Edit,
    },
    {
        name: 'zones.index',
        path: '/zonas',
        
        component: Zone.List,
    },
    {
        name: 'zones.create',
        path: '/zonas/crear',
        
        component: Zone.Create,
    },
    {
        name: 'zones.edit',
        path: '/zonas/:id/editar',
        
        component: Zone.Edit,
    },
    {
        name: 'providers.index',
        path: '/proveedores',
        
        component: Provider.List,
    },
    {
        name: 'providers.create',
        path: '/proveedores/crear',
        component: Provider.Create,
    },
    {
        name: 'providers.edit',
        path: '/proveedores/:id/editar',
        component: Provider.Edit,
    },
    {
        name: 'stock.index',
        path: '/stock',
        component: Stock.List,
    },
    {
        name: 'stock.view',
        path: '/stock/:id/ver',
        component: Stock.Show,
    },
    {
        name: 'stock.in',
        path: '/stock/ingreso',
        component: Stock.In,
    },
    {
        name: 'stock.out',
        path: '/stock/egreso',
        component: Stock.Out,
    },
    {
        name: 'products.create',
        path: '/productos/crear',
        component: Products.Create,
    },
    {
        name: 'products.edit',
        path: '/productos/:id/editar',
        component: Products.Edit,
    },
    {
        name: 'stadistics',
        path: '/estadisticas',
        component: Stadistics.View,
    }
    
].map(route => {
    route.name = `admin.${route.name}`;
    route.path = `/admin${route.path}`;

    return route;
});



const general = [
    {
        name: 'products.index',
        path: '/productos',
        component: Products.List,
    },
    {
        name: 'products.view',
        path: '/productos/:id/ver',
        component: Products.Show,
    },
    {
        name: 'orders.index',
        path: '/pedidos',
        component: Orders.List,
    },
    {
        name: 'orders.create',
        path: '/pedidos/crear',
        component: Orders.Create,
    },

    {
        name: 'orders.edit',
        path: '/pedidos/:id/editar',
        component: Orders.Edit,
    },

    {
        name: 'orders.view',
        path: '/pedidos/:id/ver',
        component: Orders.Show,
    },

    {
        name: 'customers.index',
        path: '/clientes',
        component: Customers.List,
    },
    {
        name: 'customers.create',
        path: '/clientes/crear',
        component: Customers.Create,
    },
    {
        name: 'customers.edit',
        path: '/clientes/:id/editar',
        component: Customers.Edit,
    },
    {
        name: 'customers.view',
        path: '/clientes/:id/ver',
        component: Customers.Show,
    },
    {
        name: 'list',
        path: '/listados',
        component: Stadistics.List,
    },
    {
        name: 'raises',
        path: '/recaudaciones',
        component: Raise.Show,
    },


].map(route => {
    route.name = `general.${route.name}`;
    route.path = `/general${route.path}`;

    return route;
});

export default [
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'settings.profile',
        path: '/settings/profile',
        component: Settings.Profile,
    },

    {
        name: 'settings.account',
        path: '/settings/account',
        component: Settings.Account,
    },

    ...resources,
    ...admin,
    ...general,
].map(route => {
    route.name = `backoffice.${route.name}`;
    route.auth = true;

    return route;
});
